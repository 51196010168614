import { brandName } from 'shared/variables/general'

import LoginScreen from 'screens/platform-access/LoginScreen/LoginScreen'
import LoginRecover from 'screens/platform-access/LoginRecover/LoginRecover'
import ResetPasswordScreen from 'screens/platform-access/ResetPasswordScreen/ResetPasswordScreen'
import RegisterScreen from 'screens/platform-access/RegisterScreen/RegisterScreen'

const authRoutes = [
	{
		path: '/login',
		name: brandName,
		mini: 'L',
		component: <LoginScreen />,
		layout: '/auth',
	},
	{
		path: '/recover',
		name: brandName,
		mini: 'R',
		component: <LoginRecover />,
		layout: '/auth',
	},
	{
		path: '/reset-password',
		name: 'Cambiar Contraseña',
		component: <ResetPasswordScreen />,
		layout: '/auth',
	},
	{
		path: '/register',
		name: brandName,
		mini: 'R',
		component: <RegisterScreen />,
		layout: '/auth',
	},
]

export default authRoutes
