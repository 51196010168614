import axios from 'axios'
import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_REFRESH_TOKEN_REQUEST,
	USER_REFRESH_TOKEN_SUCCESS,
	USER_REFRESH_TOKEN_FAIL,
	USER_UPDATE_MY_INFO_REQUEST,
	USER_UPDATE_MY_INFO_SUCCESS,
	USER_UPDATE_MY_INFO_FAIL,
	USER_RECOVERY_PASSWORD_REQUEST,
	USER_RECOVERY_PASSWORD_SUCCESS,
	USER_RECOVERY_PASSWORD_FAIL,
	PROFILE_GET_NOTES_REQUEST,
	PROFILE_GET_NOTES_SUCCESS,
	PROFILE_GET_NOTES_FAIL,
	PROFILE_GET_NOTES_SUCCESS_ADDED,
	PROFILE_GET_NOTES_FAIL_RESET,
	PROFILE_GET_NOTES_REQUEST_ADD,
	USER_GET_NOTES_REQUEST,
	USER_GET_NOTES_SUCCESS,
	USER_GET_NOTES_FAIL,
	USER_GET_NOTES_SUCCESS_ADDED,
	USER_GET_NOTES_FAIL_RESET,
	USER_GET_NOTES_REQUEST_ADD,
	USER_RESET_PASSWORD_REQUEST,
	USER_RESET_PASSWORD_SUCCESS,
	USER_RESET_PASSWORD_FAIL,
	USER_LOGIN_MFA_CODE_REQUEST,
	USER_LOGIN_MFA_CODE_SUCCESS,
	USER_LOGIN_MFA_CODE_FAIL,
	USER_MFA_CODE_VALIDATE_REQUEST,
	USER_MFA_CODE_VALIDATE_SUCCESS,
	USER_MFA_CODE_VALIDATE_FAIL,
	USER_CONFIRM_PASSWORD_REQUEST,
	USER_CONFIRM_PASSWORD_SUCCESS,
	USER_CONFIRM_PASSWORD_FAIL,
	USER_MFA_CODE_DISABLE_REQUEST,
	USER_MFA_CODE_DISABLE_SUCCESS,
	USER_MFA_CODE_DISABLE_FAIL,
} from '../constants/userConstants'
import parseJwt from 'shared/middlewares/parseJwt'

export const login = (email, password) => async (dispatch) => {
	try {
		dispatch({ type: USER_LOGIN_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post('/api/users/login', { email, password }, config)

		const decoded = parseJwt(data.token)
		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })

		localStorage.setItem('setCHGG', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const loginWithMFACode = (userInfoData) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_LOGIN_MFA_CODE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users/login-mfa', userInfoData, config)

		dispatch({ type: USER_LOGIN_MFA_CODE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_LOGIN_MFA_CODE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			status: error.response && error.response.status ? error.response.status : error.status,
		})
	}
}

/**
 *@route POST /api/users/mfa-validate
 */
export const postUserMFACodeValidate = (mfadata) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_MFA_CODE_VALIDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users/mfa-validate', mfadata, config)

		const decoded = parseJwt(data.token)

		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
		dispatch({ type: USER_MFA_CODE_VALIDATE_SUCCESS, payload: data.status })

		localStorage.setItem('setCHGG', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_MFA_CODE_VALIDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

/**
 *@route POST /api/users/mfa-disable
 */
export const disableUserMFA = (mfadata) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_MFA_CODE_DISABLE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users/mfa-disable', mfadata, config)

		const decoded = parseJwt(data.token)

		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
		dispatch({ type: USER_MFA_CODE_DISABLE_SUCCESS, payload: data.status })

		localStorage.setItem('setCHGG', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_MFA_CODE_DISABLE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerUser = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users', user, config)

		dispatch({ type: USER_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getUserById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/users/${id}`, config)

		dispatch({ type: USER_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getUsers = () => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/users`, config)

		dispatch({ type: USER_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const postUserConfirmPassword = (email, password) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_CONFIRM_PASSWORD_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users/confirm-password', { email, password }, config)

		dispatch({ type: USER_CONFIRM_PASSWORD_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_CONFIRM_PASSWORD_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const refreshToken = () => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_REFRESH_TOKEN_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/users/refreshtoken`, config)

		if (data.token) {
			const decoded = parseJwt(data.token)
			const userSession = {
				...decoded,
				token: data.token,
			}

			dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
			localStorage.setItem('setCHGG', JSON.stringify(data.token))
		}

		dispatch({ type: USER_REFRESH_TOKEN_SUCCESS })
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message
		if (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 502) {
			dispatch({
				type: USER_LOGOUT,
			})
		}
		dispatch({
			type: USER_REFRESH_TOKEN_FAIL,
			payload: message,
		})
	}
}

export const userUpdateMyInfo = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_UPDATE_MY_INFO_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		let formData = new FormData()

		for (let key in user) {
			formData.append(key, user[key])
		}

		const { data } = await axios.put(`/api/users/${user._id}`, formData, config)

		const decoded = parseJwt(data.token)

		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_UPDATE_MY_INFO_SUCCESS, payload: userSession })
		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
		localStorage.setItem('setCHGG', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_UPDATE_MY_INFO_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const userUpdateInfo = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/users/for-admin/${user._id}`, user, config)
		const decoded = parseJwt(data.token)
		dispatch({ type: USER_UPDATE_SUCCESS, payload: decoded })
	} catch (error) {
		dispatch({
			type: USER_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		await axios.delete(`/api/users/${id}`, config)

		dispatch({ type: USER_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: USER_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const logout = () => async (dispatch) => {
	dispatch({ type: USER_LOGOUT })
}

export const recoveryPassword = (email) => async (dispatch) => {
	try {
		dispatch({ type: USER_RECOVERY_PASSWORD_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(`/api/users/recovery/${email}`, config)

		dispatch({ type: USER_RECOVERY_PASSWORD_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_RECOVERY_PASSWORD_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const resetPassword = (resetToken, newPassword) => async (dispatch) => {
	try {
		dispatch({ type: USER_RESET_PASSWORD_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${resetToken}`,
			},
		}

		const { data } = await axios.put('/api/users/reset-password', { newPassword }, config)

		dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data.message })
	} catch (error) {
		dispatch({
			type: USER_RESET_PASSWORD_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProfileNotesById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROFILE_GET_NOTES_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/profile-notes/${id}`, config)

		dispatch({ type: PROFILE_GET_NOTES_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PROFILE_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: PROFILE_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}

export const registerProfileNote = (payload) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROFILE_GET_NOTES_REQUEST_ADD, payload: true })

		const {
			userLogin: { userInfo },
			profileNotes: { currentNotes },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const currentStateNotes = [...currentNotes]

		const { data } = await axios.post(`/api/profile-notes/`, payload, config)

		currentStateNotes.push(data)

		dispatch({ type: PROFILE_GET_NOTES_SUCCESS_ADDED, payload: true })
		dispatch({ type: PROFILE_GET_NOTES_SUCCESS, payload: currentStateNotes })
		dispatch({ type: PROFILE_GET_NOTES_REQUEST_ADD, payload: false })
		setTimeout(() => {
			dispatch({ type: PROFILE_GET_NOTES_SUCCESS_ADDED, payload: false })
		}, 3000)
	} catch (error) {
		dispatch({
			type: PROFILE_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: PROFILE_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}

export const getUserNotesById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_GET_NOTES_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/user-notes/${id}`, config)

		dispatch({ type: USER_GET_NOTES_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: USER_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}

export const registerUserNote = (payload) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_GET_NOTES_REQUEST_ADD, payload: true })

		const {
			userLogin: { userInfo },
			userNotes: { currentNotes },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const currentStateNotes = [...currentNotes]

		const { data } = await axios.post(`/api/user-notes/`, payload, config)

		currentStateNotes.push(data)

		dispatch({ type: USER_GET_NOTES_SUCCESS_ADDED, payload: true })
		dispatch({ type: USER_GET_NOTES_SUCCESS, payload: currentStateNotes })
		dispatch({ type: USER_GET_NOTES_REQUEST_ADD, payload: false })
		setTimeout(() => {
			dispatch({ type: USER_GET_NOTES_SUCCESS_ADDED, payload: false })
		}, 3000)
	} catch (error) {
		dispatch({
			type: USER_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: USER_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}
