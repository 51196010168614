import { calculateTimeRemaining } from 'shared/helpers/calculate-time-remaining'
import { formatTokenExpirationTime } from 'shared/helpers/format-token-expiration-time'
import { matchRoleName } from 'shared/matchData/matchData'

export const usersListMapper = (usersList) => {
	return usersList?.map((item) => {
		const timeRemaining = calculateTimeRemaining(item.tokenExp)
		return {
			id: item._id,
			email: item.email,
			role: matchRoleName(item.role),
			tokenExpire: formatTokenExpirationTime(timeRemaining),
		}
	})
}
