import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InputAdornment, makeStyles, IconButton } from '@material-ui/core'
import { Info, CheckCircle, VisibilityOff, Visibility } from '@mui/icons-material'
import SweetAlert from 'react-bootstrap-sweetalert'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import { resetPassword } from 'local_redux/actions/userActions'
import styles from './resetPasswordScreenStyles'

const useStyles = makeStyles(styles)

const ResetPasswordScreen = () => {
	const classes = useStyles()
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [cardAnimation, setCardAnimation] = useState('cardHidden')
	const [errorMessage, setErrorMessage] = useState('')
	const [alert, setAlert] = useState(null)
	const [searchParams] = useSearchParams()
	const resetToken = searchParams.get('resetToken')
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { loadingUserResetPassword, errorUserResetPassword, successUserResetPassword } = useSelector(
		(state) => state.userResetPassword,
	)

	const [showPassword, setShowPassword] = useState(false)
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
	const [params, setParams] = useState({
		special: false,
		lowerCase: false,
		upperCase: false,
		num: false,
		character: false,
	})

	useEffect(() => {
		if (!resetToken) {
			navigate('/auth/login')
		}
	}, [resetToken, navigate])
	useEffect(() => {
		const id = setTimeout(() => {
			setCardAnimation('')
		}, 700)
		return function cleanup() {
			window.clearTimeout(id)
		}
	}, [])
	useEffect(() => {
		if (successUserResetPassword) {
			setPassword('')
			setConfirmPassword('')
			setParams({
				special: false,
				lowerCase: false,
				upperCase: false,
				num: false,
				character: false,
			})
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px', color: '#333333' }}
					title='Hecho!'
					onConfirm={() => navigate('/auth/login')}
					onCancel={() => navigate('/auth/login')}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Se ha actualizado la contraseña.
				</SweetAlert>,
			)
		}
	}, [successUserResetPassword, navigate])

	const handleRequirements = (e) => {
		const value = e.target.value

		const special = /^(?=.*[.,!@#$^&*()_-]).{1,}$/
		const num = /^(?=.*\d).{1,}$/
		const lowerCase = /^(?=.*[a-z]).{1,}$/
		const upperCase = /^(?=.*[A-Z]).{1,}$/
		const character = /^.{6,}$/

		setParams({
			special: special.test(value),
			num: num.test(value),
			lowerCase: lowerCase.test(value),
			upperCase: upperCase.test(value),
			character: character.test(value),
		})
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (password !== confirmPassword) {
			setErrorMessage('Las contraseñas no coinciden')
			return
		}
		if (Object.values(params).includes(false)) {
			setErrorMessage('La nueva contraseña no cumple con los requisitos.')
			return
		}
		setErrorMessage('')
		dispatch(resetPassword(resetToken, password))
	}

	if (!resetToken) {
		return null
	}

	return (
		<div className={classes.container}>
			<GridContainer justifyContent='center'>
				<GridItem xs={12} sm={8} md={4}>
					<form onSubmit={handleSubmit}>
						<Card loginRegister className={classes[cardAnimation]}>
							<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
								<h4 className={classes.cardTitle}>Restablecer Contraseña</h4>
							</CardHeader>
							<CardBody>
								<CustomInput
									labelText='Nueva Contraseña'
									id='password'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: showPassword ? 'text' : 'password',
										value: password,
										onChange: (e) => {
											setPassword(e.target.value)
											handleRequirements(e)
										},
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={() => setShowPassword((prev) => !prev)}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<CustomInput
									labelText='Confirmar Contraseña'
									id='confirmPassword'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: showPasswordConfirm ? 'text' : 'password',
										value: confirmPassword,
										onChange: (e) => setConfirmPassword(e.target.value),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={() => setShowPasswordConfirm((prev) => !prev)}
													edge='end'
												>
													{showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<ul className={classes.reqPassword}>
									<li>
										<div className={classes.li}>
											{params.character ? (
												<CheckCircle sx={{ color: '#d1cdc2', mr: 1, fontSize: '15px' }} />
											) : (
												<Info sx={{ color: '#FFC300', mr: 1, fontSize: '15px' }} />
											)}
											Al menos 6 dígitos
										</div>
									</li>
									<li>
										<div className={classes.li}>
											{params.lowerCase ? (
												<CheckCircle sx={{ color: '#d1cdc2', mr: 1, fontSize: '15px' }} />
											) : (
												<Info sx={{ color: '#FFC300', mr: 1, fontSize: '15px' }} />
											)}
											Al menos 1 letra minúscula
										</div>
									</li>
									<li>
										<div className={classes.li}>
											{params.upperCase ? (
												<CheckCircle sx={{ color: '#d1cdc2', mr: 1, fontSize: '15px' }} />
											) : (
												<Info sx={{ color: '#FFC300', mr: 1, fontSize: '15px' }} />
											)}
											Al menos 1 letra mayúscula
										</div>
									</li>
									<li>
										<div className={classes.li}>
											{params.num ? (
												<CheckCircle sx={{ color: '#d1cdc2', mr: 1, fontSize: '15px' }} />
											) : (
												<Info sx={{ color: '#FFC300', mr: 1, fontSize: '15px' }} />
											)}
											Al menos 1 número
										</div>
									</li>
									<li>
										<div className={classes.li}>
											{params.special ? (
												<CheckCircle sx={{ color: '#d1cdc2', mr: 1, fontSize: '15px' }} />
											) : (
												<Info sx={{ color: '#FFC300', mr: 1, fontSize: '15px' }} />
											)}
											Al menos 1 carácter especial
										</div>
									</li>
								</ul>
								{errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<Button type='submit' color='primary' size='lg'>
									{loadingUserResetPassword ? 'Restableciendo...' : 'Restablecer Contraseña'}
								</Button>
							</CardFooter>
							{errorUserResetPassword && <div className={classes.errorMessage}>{errorUserResetPassword}</div>}
							{successUserResetPassword && (
								<div className={classes.successMessage}>Contraseña restablecida con éxito</div>
							)}
						</Card>
					</form>
				</GridItem>
			</GridContainer>
			{alert}
		</div>
	)
}

export default ResetPasswordScreen
