import { Visibility } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/CustomButtons/Button'

export const usersListColumn = ({ showUpdateUserHandler, showDeleteInfoHandler, setIdUser }) => {
	return [
		{
			header: 'Email',
			accessor: 'email',
		},
		{
			header: 'Rol',
			accessor: 'role',
			disableFilters: true,
		},
		{
			header: 'Token caduca',
			accessor: 'tokenExpire',
		},
		{
			header: 'Actions',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div className='actions-right'>
					<Button justIcon round simple onClick={() => showUpdateUserHandler(row.id)} color='success' className='edit'>
						<Visibility />
					</Button>
					<Button
						justIcon
						round
						simple
						onClick={() => [showDeleteInfoHandler(row.id), setIdUser(row.id)]}
						color='danger'
						className='delete'
					>
						<DeleteIcon />
					</Button>
				</div>
			),
		},
	]
}
