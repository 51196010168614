/**
 * @param {String} seconds
 * @returns string seconds formated in 00h 00m || Token Expirado || Sin Token
 */
export const formatTokenExpirationTime = (seconds) => {
	if (seconds <= 0) return 'Token Expirado'

	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const expiresIn = isNaN(hours) || isNaN(minutes) ? 'Sin Token' : `${hours}h ${minutes % 60}m`

	return expiresIn
}
