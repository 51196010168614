import { container, cardTitle, whiteColor, grayColor } from 'assets/jss/material-dashboard-pro-react'

const styles = (theme) => ({
	container: {
		...container,
		zIndex: '4',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '100px',
		},
	},
	cardRoot: {
		borderRadius: 0,
	},
	cardTitle: {
		...cardTitle,
		color: '#fff !important',
	},
	textCenter: {
		textAlign: 'center',
	},
	justifyContentCenter: {
		justifyContent: 'center !important',
	},
	customButtonClass: {
		'&,&:focus,&:hover': {
			color: whiteColor,
		},
		marginLeft: '5px',
		marginRight: '5px',
	},
	inputAdornment: {
		marginRight: '18px',
	},
	inputAdornmentIcon: {
		color: grayColor[6],
	},
	cardHidden: {
		opacity: '0',
		transform: 'translate3d(0, -60px, 0)',
	},
	cardHeader: {
		marginBottom: '20px',
		padding: '23px !important',
	},
	socialLine: {
		padding: '0.9375rem 0',
	},
	messageError: {
		textAlign: 'center',
		color: 'red',
		margin: '20px 0',
	},
	mainBtnRecovery: {
		textAlign: 'center',
		margin: '10px 0px',
	},
	btnRecovery: {
		textTransform: 'none',
		textAlign: 'center',
		width: '100%',
	},
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},
})

export default styles
