import { container, cardTitle, whiteColor, grayColor } from 'assets/jss/material-dashboard-pro-react.js'

const styles = (theme) => ({
	container: {
		...container,
		zIndex: '4',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '100px',
		},
	},
	reqPassword: {
		listStyleType: 'none',
		fontSize: '10px',
		marginTop: '10px',
		padding: '0 16px',
		lineHeight: 1.1,
	},
	cardRoot: {
		borderRadius: 0,
	},
	cardTitle: {
		...cardTitle,
		color: '#fff !important',
	},
	textCenter: {
		textAlign: 'center',
	},
	justifyContentCenter: {
		justifyContent: 'center !important',
		padding: '0 !important',
	},
	customButtonClass: {
		'&,&:focus,&:hover': {
			color: whiteColor,
		},
		marginLeft: '5px',
		marginRight: '5px',
	},
	inputAdornment: {
		marginRight: '18px',
	},
	inputAdornmentIcon: {
		color: grayColor[6],
	},
	cardHidden: {
		opacity: '0',
		transform: 'translate3d(0, -60px, 0)',
	},
	cardHeader: {
		marginBottom: '20px',
		padding: '23px !important',
	},
	socialLine: {
		padding: '0.9375rem 0',
	},
	messageError: {
		textAlign: 'center',
		color: 'red',
		margin: '5px 0',
	},
	li: {
		display: 'flex',
		alignItems: 'center',
	},
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},
	errorMessage: {
		color: 'red',
		textAlign: 'center',
		marginBottom: '10px',
	},
	successMessage: {
		textAlign: 'center',
		marginBottom: '10px',
		textTransform: 'none',
	},
})

export default styles
