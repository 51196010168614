import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import { InputAdornment, makeStyles } from '@material-ui/core'
import { AlternateEmail } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import { recoveryPassword } from 'local_redux/actions/userActions'
import { USER_RECOVERY_PASSWORD_RESET } from 'local_redux/constants/userConstants'
import styles from '../LoginScreen/styles/loginScreenStyles'

const useStyles = makeStyles(styles)

const LoginRecover = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()

	const [email, setEmail] = useState('')
	const [alert, setAlert] = useState(null)
	const [validationError, setValidationError] = useState('')
	const [cardAnimaton, setCardAnimation] = useState('cardHidden')

	const { loadingUserRecoveryPassword, successUserRecoveryPassword, errorUserRecoveryPassword } = useSelector(
		(state) => state.userRecoveryPassword,
	)

	const confirmSuccess = () => {
		setEmail('')
		dispatch({ type: USER_RECOVERY_PASSWORD_RESET })
		setAlert(null)
		navigate('/auth/login')
	}

	useEffect(() => {
		let id = setTimeout(function () {
			setCardAnimation('')
		}, 200)
		return function cleanup() {
			window.clearTimeout(id)
		}
	})
	useEffect(() => {
		if (successUserRecoveryPassword) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px', color: '#333333' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					onCancel={() => setAlert(null)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Se ha enviado correctamente su petición.
					<br />
					Acabamos de enviar a su correo electrónico una contraseña que deberá cambiar al primer inicio de sesión.
				</SweetAlert>,
			)
		}
	}, [successUserRecoveryPassword])

	const recoverHandler = (e) => {
		e.preventDefault()

		if (!email) {
			return setValidationError('Por favor ingresa tu correo electrónico para continuar')
		}
		if (validationError) {
			setValidationError('')
		}

		dispatch(recoveryPassword(email.trim()))
	}

	return (
		<div className={classes.container}>
			<GridContainer justifyContent='center'>
				<GridItem xs={12} sm={8} md={4}>
					<form onSubmit={recoverHandler} autoComplete='off'>
						<Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
							<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
								<h4 className={classes.cardTitle}>Recupere su Cuenta</h4>
							</CardHeader>
							<CardBody style={{ paddingTop: 40 }}>
								<label className={classes.labelInput}>
									Indíquenos su correo electrónico para recuperar su contraseña.
								</label>
								<CustomInput
									labelText='Correo Electrónico'
									id='email'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: 'email',
										value: email,
										onChange: (e) => setEmail(e.target.value),
										endAdornment: (
											<InputAdornment position='end'>
												<AlternateEmail className={classes.inputAdornmentIcon} />
											</InputAdornment>
										),
									}}
								/>
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<GridContainer>
									<GridItem xs={12}>
										<Button type='submit' color='primary' size='lg' block>
											{loadingUserRecoveryPassword ? 'Recuperando...' : 'Recuperar'}
										</Button>
									</GridItem>
									<GridItem xs={12} className={classes.mainBtnRecovery}>
										<Button
											type='button'
											size='sm'
											color='transparent'
											onClick={() => navigate('/auth/login')}
											className={classes.btnRecovery}
											variant='body2'
										>
											Iniciar Sesión
										</Button>
									</GridItem>
									{errorUserRecoveryPassword && (
										<GridItem xs={12}>
											<div className={classes.messageError}>{errorUserRecoveryPassword}</div>
										</GridItem>
									)}
									{validationError && (
										<GridItem xs={12}>
											<div className={classes.messageError}>{validationError}</div>
										</GridItem>
									)}
								</GridContainer>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
			{alert}
		</div>
	)
}

export default LoginRecover
